import { useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import useInfo from '~/services/useInfo'
import BackgroundWithIcons from '../elements/BackgroundWithIcons'
import Logo from '../elements/Logo'
import type { GooglePlace } from '~/templates/page'
import Content from '../elements/Content'

export interface FooterLoaderData extends Omit<LoaderData, 'footer' | 'page'> {
  footer: Component_Footer
  wpUrl: string
}

export default function Footer() {
  const { footer, wpUrl, place } = useLoaderData<FooterLoaderData & { place: GooglePlace }>()
  const info = useInfo()

  return (
    <footer className="bg-rc-shedinja mt-12 lg:mt-20 relative">
      <div className="container relative z-10">
        <LosseLink
          to="/"
          aria-label="Home"
          className="absolute top-0 left-4 sm:left-0 lg:left-12 w-[115px] h-[125px] md:w-[138px] md:h-[150px]"
        >
          <Logo />
        </LosseLink>

        <div className="pb-16 sm:pb-24 pt-48 lg:pr-60">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <h2 className="text-base font-bold">Adres</h2>
              <div className="content !font-rc-plus !font-light mt-2">
                <p>
                  {info.addressOne}
                  <br />
                  {info.addressTwo}
                  <span className="flex items-center mt-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px]"
                    >
                      <g clipPath="url(#clip0_1147_2305)">
                        <path
                          d="M11.8335 13.5508C9.40716 12.5235 7.47771 10.5896 6.456 8.16083L9.28933 5.3225L4.07266 0.101667L1.43016 2.74333C0.97183 3.20429 0.609452 3.75155 0.363996 4.35346C0.118539 4.95537 -0.00512351 5.59999 0.000162584 6.25C0.000162584 12.29 7.71016 20 13.7502 20C14.4001 20.0057 15.0447 19.8822 15.6465 19.6367C16.2484 19.3912 16.7954 19.0286 17.256 18.57L19.8985 15.9275L14.6777 10.7067L11.8335 13.5508ZM16.0768 17.3917C15.7708 17.695 15.4075 17.9345 15.0081 18.0962C14.6086 18.2578 14.181 18.3385 13.7502 18.3333C8.55599 18.3333 1.66683 11.4442 1.66683 6.25C1.66192 5.81903 1.74263 5.39137 1.90428 4.99183C2.06593 4.59229 2.30529 4.22882 2.6085 3.9225L4.07266 2.45833L6.93683 5.3225L4.491 7.76833L4.69516 8.28C5.2962 9.88776 6.2358 11.3475 7.45029 12.5605C8.66478 13.7734 10.1258 14.7111 11.7343 15.31L12.2393 15.5025L14.6777 13.0633L17.5418 15.9275L16.0768 17.3917ZM11.6668 1.66667V0C13.8762 0.00242633 15.9944 0.881179 17.5567 2.44346C19.119 4.00573 19.9977 6.12394 20.0002 8.33333H18.3335C18.3315 6.56583 17.6285 4.87129 16.3787 3.62148C15.1289 2.37167 13.4343 1.66865 11.6668 1.66667ZM11.6668 5V3.33333C12.9925 3.33466 14.2635 3.86187 15.2009 4.79926C16.1383 5.73666 16.6655 7.00766 16.6668 8.33333H15.0002C15.0002 7.44928 14.649 6.60143 14.0239 5.97631C13.3987 5.35119 12.5509 5 11.6668 5Z"
                          fill="#F2F2E9"
                        />
                      </g>
                    </svg>
                    <a href={`tel:${info.phonenumber}`} className="hover:!text-rc-quilladin">
                      {info.phonenumber}
                    </a>
                  </span>
                  <span className="flex items-center max-sm:mt-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px]"
                    >
                      <g clipPath="url(#clip0_1147_2288)">
                        <path
                          d="M17.5 0.833618H2.5C1.83696 0.833618 1.20107 1.09701 0.732233 1.56585C0.263392 2.03469 0 2.67058 0 3.33362L0 19.167H20V3.33362C20 2.67058 19.7366 2.03469 19.2678 1.56585C18.7989 1.09701 18.163 0.833618 17.5 0.833618V0.833618ZM2.5 2.50028H17.5C17.721 2.50028 17.933 2.58808 18.0893 2.74436C18.2455 2.90064 18.3333 3.1126 18.3333 3.33362V3.88945L11.7683 10.4553C11.2987 10.923 10.6628 11.1857 10 11.1857C9.33715 11.1857 8.70131 10.923 8.23167 10.4553L1.66667 3.88945V3.33362C1.66667 3.1126 1.75446 2.90064 1.91074 2.74436C2.06702 2.58808 2.27899 2.50028 2.5 2.50028V2.50028ZM1.66667 17.5003V6.25029L7.05333 11.6336C7.83552 12.4138 8.89521 12.852 10 12.852C11.1048 12.852 12.1645 12.4138 12.9467 11.6336L18.3333 6.25029V17.5003H1.66667Z"
                          fill="#F2F2E9"
                        />
                      </g>
                    </svg>

                    <a href={`mailto:${info.emailAddress}`} className=" hover:!text-rc-quilladin">
                      {info.emailAddress}
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <h2 className="text-base font-bold">Openingstijden</h2>
              <div className="content mt-2 !font-rc-plus !font-light">
                <p>
                  <Content className="">{place?.openings}</Content>
                  {info.gelateriaExtraOpen && (
                    <>
                      <br />
                      <span className="flex items-start">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-1 sm:mr-2 min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px]"
                        >
                          <path
                            d="M16.9003 4.02044C16.663 2.89664 16.0467 1.88854 15.1546 1.16511C14.2625 0.441677 13.1488 0.046875 12.0003 0.046875C10.8517 0.046875 9.73805 0.441677 8.84594 1.16511C7.95384 1.88854 7.33752 2.89664 7.10028 4.02044C6.17453 4.09408 5.28243 4.4006 4.50691 4.91149C3.7314 5.42239 3.09765 6.12107 2.66459 6.94259C2.23153 7.76411 2.01322 8.68179 2.02995 9.61031C2.04668 10.5388 2.2979 11.4481 2.76028 12.2534L12.0003 23.9904L21.2403 12.2534C21.7029 11.4481 21.9544 10.5388 21.9712 9.61021C21.9881 8.68159 21.7698 7.76379 21.3367 6.94218C20.9036 6.12058 20.2697 5.42186 19.4941 4.91102C18.7184 4.40018 17.8261 4.09382 16.9003 4.02044ZM20.0003 9.50044C19.9988 9.66792 19.9848 9.83505 19.9583 10.0004H13.0453C12.5093 5.07544 19.8003 4.54944 20.0003 9.50044ZM12.0003 2.00044C12.6632 2.00297 13.3066 2.22503 13.83 2.63192C14.3534 3.0388 14.7273 3.6076 14.8933 4.24944C13.7238 4.60706 12.7063 5.3429 12.0003 6.34144C11.2943 5.3429 10.2767 4.60706 9.10728 4.24944C9.27323 3.6076 9.64711 3.0388 10.1705 2.63192C10.6939 2.22503 11.3373 2.00297 12.0003 2.00044ZM7.50028 6.00044C8.00139 6.00174 8.49638 6.11063 8.95177 6.31975C9.40717 6.52888 9.81234 6.83335 10.1399 7.21258C10.4675 7.59182 10.7098 8.03697 10.8504 8.51793C10.9911 8.9989 11.0269 9.50446 10.9553 10.0004H4.04227C3.97065 9.50421 4.00647 8.99839 4.14732 8.51721C4.28816 8.03604 4.53074 7.59073 4.85863 7.21145C5.18652 6.83217 5.59207 6.52776 6.04783 6.31882C6.50358 6.10989 6.99891 6.00131 7.50028 6.00044ZM5.10028 12.0004H18.9103L12.0003 20.7604L5.10028 12.0004Z"
                            fill="#F2F2E9"
                          />
                        </svg>
                        <i>{info.gelateriaExtraOpen}</i>
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-16 lg:mt-24 grid grid-cols-6 gap-6 content--paragraphs">
            <div className="col-span-3 sm:col-span-2">
              <h2 className="text-base font-bold font-rc-plus">Menu</h2>
              <nav className="mt-2 max-md:text-sm !font-rc-plus !font-light">
                <ul>
                  {footer?.menuFooter?.map((item, index: number) => (
                    <li key={index}>
                      <LosseLink className="hover:underline" to={item?.link?.url || '/'} target={item?.link?.target || ''}>
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="col-span-3 sm:col-span-2">
              <h2 className="text-base font-bold font-rc-plus">Klantenservice</h2>
              <nav className="mt-2 max-md:text-sm !font-rc-plus !font-light">
                <ul>
                  {footer?.menuServices?.map((item, index: number) => (
                    <li key={index}>
                      <LosseLink
                        className="hover:underline"
                        to={item?.link?.url?.replace('/__asset?url=', `${wpUrl}`) || '/'}
                        target={item?.link?.target || ''}
                      >
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                  <li>
                    <button
                      type="button"
                      className="hover:underline"
                      onClick={() => {
                        // @ts-expect-error - Cookiebot is loaded after the page is loaded
                        if (typeof Cookiebot !== 'undefined') Cookiebot.show()
                      }}
                    >
                      Cookies
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-span-6 sm:col-span-2">
              <h2 className="text-base font-bold font-rc-plus">Volg ons op social</h2>
              <div className="mt-2 flex gap-2">
                {info.facebookUrl && (
                  <a href={info?.facebookUrl || ''} target="_blank" className="text-white" rel="noreferrer">
                    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                        fill="white"
                      ></path>
                    </svg>
                  </a>
                )}
                {info?.instagramUrl && (
                  <a href={info?.instagramUrl || ''} target="_blank" className="text-white" rel="noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </a>
                )}
                {info?.linkedinUrl && (
                  <a href={info?.linkedinUrl || ''} target="_blank" className="text-white" rel="noreferrer">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.47255 6.25H0V20H4.47255V6.25Z" fill="#F2F2E9" />
                      <path
                        d="M16.6543 6.41113C16.6069 6.39648 16.5615 6.37988 16.5117 6.36621C16.4517 6.35254 16.3916 6.34131 16.3306 6.33105C16.0933 6.28369 15.833 6.25 15.5278 6.25C12.9205 6.25 11.2667 8.14648 10.7218 8.87891V6.25H6.24976V20H10.7223V12.5C10.7223 12.5 14.1021 7.79248 15.5283 11.25V20H19.9999V10.7212C19.9999 8.64356 18.5761 6.9126 16.6548 6.41113H16.6543Z"
                        fill="#F2F2E9"
                      />
                      <path
                        d="M4.37489 2.1875C4.37489 3.39551 3.39542 4.375 2.18745 4.375C0.979468 4.375 0 3.39551 0 2.1875C0 0.979492 0.979468 0 2.18745 0C3.39542 0 4.37489 0.979492 4.37489 2.1875Z"
                        fill="#F2F2E9"
                      />
                    </svg>
                  </a>
                )}
                <span className="ml-2 !font-rc-plus !font-light">@RengersCorner</span>
              </div>

              <div className="mt-20 flex max-sm:justify-center gap-x-2 max-md:text-sm !font-rc-plus !font-light">
                Veilig betalen met <img src="/static/ideal-white.svg" alt="IDeal Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute right-0 top-0 z-0">
        <BackgroundWithIcons />
      </div>
    </footer>
  )
}
